export class generalModel {
	name: string
	firstName: string
	lastName: string
	fullContactNo: number
	email
	iso2: any = "in"
	countryCode = 91
	status: string
	country: string
	city: string
	state: string
}
    
export const menu=[
	{name:"dashboard",path:"dashboard",value:[]},
    
]
export const icons = {
	dashboard:"mdi mdi-chart-pie",
	users:"mdi mdi-account-group",
	verificationRequests:"mdi mdi-verified",
	interestsManagement:"mdi mdi-star-face", 
	payments:"mdi mdi-cash",
	planManagement:"mdi mdi-buffer",
	broadcast:"mdi mdi-access-point-network",
	subAdmins:"mdi mdi-account-plus",
	generalSettings: "mdi mdi-settings",
	feedback: "mdi mdi-playlist-edit",
	edit: "fa fa-pencil",
	versions:"fa fa-pencil",
	"contact-us": "fa fa-pencil",
	"cant-bid": "fa fa-briefcase",
	"cancel-reason":"fa fa-briefcase",
	"cancel-vendor-reason":"fa fa-briefcase",
	"reject-reason":"fa fa-briefcase",
	reports:"mdi mdi-account-edit",
	documents:"fa fa-briefcase",
	"notification-sound":"fa fa-briefcase",
	cancel: "fa fa-times",
	delete:"fa fa-trash",
	unlock:"fa fa-unlock",
	lock:"fa fa-lock",
	decline:"fa fa-briefcase",
	requested:"fa fa-briefcase",
	accepted:"fa fa-briefcase",
	completed:"fa fa-briefcase",
	cancelledJobs:"fa fa-briefcase",
	customer:"fa fa-briefcase",
	jobs:"fa fa-briefcase",
	coupon:"fa fa-briefcase",
	createJob:"fa fa-briefcase",
	admin:"fa fa-briefcase",
	cms:"fa fa-briefcase",
	accessControl:"fa fa-briefcase",
	adminNotifications:"fa fa-briefcase",
	faq:"fa fa-briefcase",
	howItWorks:"fa fa-briefcase",
	contactUs:"fa fa-briefcase",
	termsAndCondition:"fa fa-briefcase",
	termsAndConditionForUser:"fa fa-briefcase",
	aboutUs:"fa fa-briefcase",
	notifications:"fa fa-briefcase",
	copyCategory:"fa fa-briefcase",
	automaticPush:"fa fa-briefcase",
	smsTemplate:"fa fa-briefcase",
	setting:"fa fa-briefcase",
	languageLabel:"fa fa-briefcase",
	notificationSound:"fa fa-briefcase",
	seo:"fa fa-briefcase",
	transaction:"fa fa-briefcase",
	general:"fa fa-briefcase",
	socialMedia:"fa fa-briefcase",
	analytic:"fa fa-briefcase",
	emailTemplate:"fa fa-briefcase",
	orders:"fa fa-briefcase",
	orderListing:"fa fa-briefcase",
	subscription:"fa fa-briefcase",
	validation:"fa fa-briefcase",
	reviews:"fa fa-briefcase",
	myNotifications:"fa fa-briefcase",
}

export const Month = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
export const Week = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"]

export const BtnText = {
	week:"Week",
	month:"Month",
	year:"Year",
	daily:"Daily",
	current:"Today",
	previous:"Previous",
	next:"Next"
}

export const tabArray = [
	// {label:'',name:'customer',value:[0,1,2,3,4]},
 
]
export const optionArray = [
	{name:"view",value:false},    // 0
	{name:"add",value:false},    // 1
	{name:"edit",value:false},    // 2
	{name:"delete",value:false},    // 3
	{name:"status",value:false},    // 4
	{name:"refund",value:false},    // 5
]

export const permission ={status:"default"}
for(const x of tabArray)
{
	const array1={}
	for(const y of x.value)
	{
		array1[optionArray[y].name]=optionArray[y].value 
	}
	permission[x.name] = array1
}


