<div class="login-wrapper loginType_3">
		
    <div class="login-container">
        
        <div class="col-set-6 login-content-1">
            <div class="image_bx">
            <figure class="logo">
                <img src="../../../assets/images/logo1.png">
            </figure>
            <h2>We love creative Business Ideas</h2>	
            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text </p>
            <ul>
                <li>Leverage agile frameworks to provide a</li>
                <li>Robust Synopsis for high level overviews</li>
                <li>Robust Synopsis for high level overviews</li>				
            </ul>	
            </div>
        </div>
        <div class="col-set-6 login-content-2">
            <div class="form-bx login-form show">
                <h2>Set ew password to your account</h2>
                <form [formGroup]="form" (ngSubmit)="ChangePwd()">
                    
                    
                    <div class="form-group"><label>New Password*</label>
                        <div class="input-icon py-2">
                            <input autocomplete="off" placeholder="Enter Password" style="text-transform:none;" class="form-control" formControlName="newPwd" [type]="isTextFieldType1 ? 'text' : 'password'">
                            <i class="{{isTextFieldType1 ?'fa fa-eye-slash':'fa fa-eye'}}" (click)="togglePasswordFieldType(1)"></i>
                        </div>
                        <!-- <input autocomplete="off" class="form-control" formControlName="newPwd" type="password"> -->
                        <div class="text-danger" *ngIf="form.controls['newPwd'].hasError('required') && showError">
                            *Please enter new password.
                        </div>

                        <div class="text-danger" *ngIf="form.controls['newPwd'].hasError('minlength') && showError">
                            *Minimum length of 6 characters.
                        </div>
                    </div>
                    <div class="form-group"><label>Confirm Password*</label>
                        <div class="input-icon py-2">
                            <input autocomplete="off" class="form-control" style="text-transform:none;" placeholder="Enter Password" formControlName="confirm" [type]="isTextFieldType2 ? 'text' : 'password'">
                            <i class="{{isTextFieldType2 ?'fa fa-eye-slash':'fa fa-eye'}}" (click)="togglePasswordFieldType(2)"></i>
                        </div>
                        <!-- <input autocomplete="off" class="form-control" formControlName="confirm" type="password"> -->
                        <div class="text-danger" *ngIf="form.controls['confirm'].hasError('required') && showError">
                            *Please enter confirm password.
                        </div>

                        <div class="text-danger" *ngIf="form.controls['confirm'].hasError('minlength') && showError">
                            *Minimum length of 6 characters.
                        </div>

                        <div class="text-danger" *ngIf="showError1 && !showError">
                            *Password does not match
                        </div>
                    </div>
                   
                    <div class="form-element buttons">
                        <!-- <input type="submit" class="submit button" value=" LOGIN " /> -->
                            <button type="submit"  class="submit button"><span></span>Change Password</button>
                    </div>
                </form>
            </div>
            
        </div>		
    			
    </div>

		
</div>