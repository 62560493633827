<div class="login-wrapper loginType_4 inner-page">

    <div class="login-container ibox" [class.slideAnimation]="showFilp === true">



        <div class="col-set-6 login-content-1 ibox">
            <div class="image_bx">
                <figure class="logo">
                    <img src="../../../assets/images/logo1.png">
                </figure>
                <h2>We love creative Business Ideas</h2>
                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text </p>
                <ul>
                    <li>Leverage agile frameworks to provide a</li>
                    <li>Robust Synopsis for high level overviews</li>
                    <li>Robust Synopsis for high level overviews</li>
                </ul>
            </div>
        </div>
        <div class="col-set-6 login-content-2">
            <div class="form-bx login-form show ibox">
                <h2>Login to account</h2>
                <form [formGroup]="form" (ngSubmit)="loginFun()">
                    <div class="form-element">
                        <label>Email</label>
                        <input type="email" autofocus="" placeholder="Enter email" formControlName="email" autocomplete="off" [(ngModel)]="loginModel.email">
                        <div class="text-danger" *ngIf="form.controls['email'].hasError('required') && showError">
                            *Please enter an email address.
                        </div>
                        <div class="text-danger" *ngIf="form.controls['email'].hasError('email') && showError">
                            *Please enter valid email address.
                        </div>
                    </div>
                    <div class="form-element">
                        <label>Password</label>
                        <input type="password" placeholder="Enter password" formControlName="password" autocomplete="off" [(ngModel)]="loginModel.password">
                        <div class="text-danger" *ngIf="form.controls['password'].hasError('required') && showError">
                            *Please enter password.
                        </div>

                    </div>
                    <div class="form-element forgot-pwd">
                        <label class="checkbox leftlabel"><input type="checkbox" [checked]="isRemember" (change)="changeRememberme($event)"><i class="input-helper"></i>Remember me</label>
                        <a id="moveleft" href="javascript:void(0)" (click)="flipAnimation(true)" class="linkright flip-change">Forgot password?</a>
                    </div>

                    <div class="form-element buttons">
                        <!-- <input type="submit" class="submit button" value=" LOGIN " /> -->
                        <button type="submit" class="submit button"><span></span>Log In</button>
                    </div>

                    <div class="form-element setThemes">
                        <label>Select Theme</label>
                        <select [(ngModel)]="currentTheme" [ngModelOptions]="{standalone: true}" (change)="changeTheme($event.target.value)">
                            <option *ngFor="let theme of themes" [value]="theme.value" [selected]="theme.value==currentTheme"> {{ theme.name }}</option>
                          </select>
                    </div>
                </form>
            </div>
            <div class="form-bx forgot-form">
                <h2>Forgot password</h2>
                <p class="message">Please enter your email address. You will receive a link to create a new password via email.</p>
                <form [formGroup]="resetPasswordForm" (ngSubmit)="resetPassword()">
                    <div class="form-element">
                        <label>Email</label>
                        <input type="email" autofocus="" formControlName="email" placeholder="Enter email" [(ngModel)]="resetPasswordModel.email" autocomplete="off">
                        <div class="text-danger" *ngIf="resetPasswordForm.controls['email'].hasError('required') && showError">
                            *Please enter an email address.
                        </div>
                        <div class="text-danger" *ngIf="resetPasswordForm.controls['email'].hasError('email') && showError">
                            *Please enter valid email address.
                        </div>
                    </div>

                    <div class="form-element restPassword buttons">

                        <button type="submit" class="submit button"><span></span>Get New Password</button>
                    </div>
                    <div class="center">
                        <div class="flip-back" (click)="flipAnimation(false)">Back to login</div>
                    </div>
                </form>
            </div>
        </div>

    </div>


</div>