export const apiUrl = {
	_adminLogin: "admin/v1/admin/login",
	_forgotPassword: "admin/v1/admin/forgot-password",
	_resetPassword: "admin/reset_password",
	_changePassword: "admin/v1/admin/profile/change-password",
	_dashboard:"admin/v1/common/dashboard",
	_dashboardNewUsers:"admin/v1/common/dashboard/newUsers",
	_dashboardActiveUsers:"admin/v1/common/dashboard/activeUsers",
	_dashboardTop10:"admin/v1/common/dashboard/top10",

	_notification:"admin/v1/notification",
	_Notificationdetail:"admin/v1/notification/detail",
	_getcategiryDetails:"admin/v1/categories/detail",
	_categories:"admin/v1/categories",
	_getappversen:"admin/v1/systemConfiguration/appVersion",
	_getappVertionDetails:"admin/v1/systemConfiguration/appVersion/detail",
	_uplod:"admin/v1/common/getSignedURL",

	_getachievements:"admin/v1/achievements",
	_saveAchievement:"admin/v1/achievements/",
	_editAchievement:"admin/v1/achievements/",
	_getAchievementDetails:"admin/v1/achievements/detail",
	_getAchievementLevels:"admin/v1/achievementLevels",
	_getAchievementLevelDetails:"admin/v1/achievementLevels/detail",

	_getAchievementRules:"admin/v1/achievementRules",
	_getAchievementRulesDetails:"admin/v1/achievementRules/detail",
	_saveAchievementRules:"admin/v1/achievementRules",
	_editAchievementRules:"admin/v1/achievementRules",
	_getAchievementRuleDetails:"admin/v1/achievementRules/detail",

	_saveAchievementLevels:"admin/v1/achievementLevels",
	_editAchievementLevels:"admin/v1/achievementLevels",

	_adminListing:"admin/v1/admin/list",
	_adminDetail:"admin/v1/admin/adminDetail",
	_adminDelete:"admin/v1/admin",
	_addAdmin:"admin/v1/admin",
	_editAdmin:"admin/v1/admin",

	_userListing:"admin/v1/users",
	_userBlock:"admin/v1/users/block",
	_userDelete:"admin/v1/users",
	_userDetails:"admin/v1/users",
	_updatePassword:"admin/v1/admin/profile/change-password",
	_updateProfile:"admin/v1/admin/edit-profile",

	_putresetPassword:"admin/v1/admin/profile/reset-password",
	_getReportedItems: "admin/v1/reportedItems",
	_getReportedItemsDetails : "admin/v1/reportedItems/detail",
	_updateReportedItems : "admin/v1/reportedItems",
	_getReportedItemsCount : "admin/v1/reportedItems/counts",

	_getReportedBugs : "admin/v1/reportedBugs",
	_getReportedBugsDetails : "admin/v1/reportedBugs/detail",
	_updateReportedBugs: "admin/v1/reportedBugs",
	_getReportedBugsCount : "admin/v1/reportedBugs/counts",
	_getUserItemsCount:"admin/v1/users/counts"
}
