
import { NgModule } from "@angular/core"
import { Routes, RouterModule, PreloadAllModules, ExtraOptions } from "@angular/router"
import { LoginComponent } from "./external/login/login.component"
import { AuthGuard } from "./service/auth.service"
import {ResetPwdComponent} from "./external/reset-pwd/reset-pwd.component"
import {
	NbAuthComponent,
	NbLoginComponent,
	NbLogoutComponent,
	NbRegisterComponent,
	NbRequestPasswordComponent,
	NbResetPasswordComponent,
} from "@nebular/auth"

export const routes: Routes = [
	{ path: "", 
		redirectTo: "/login",
		pathMatch: "full", 
	},
	{
		path: "login",
		component: LoginComponent,
	},  
  
	{path:"settings/:token",component:ResetPwdComponent},
	{
		path: "",
		canActivate: [AuthGuard], 
		loadChildren: () => import("./internal/internal.module")
			.then(m => m.PagesModule),
	},
	// {
	//   path: 'auth',
	//   component: NbAuthComponent,
	//   children: [
	//     {
	//       path: '',
	//       component: NbLoginComponent,
	//     },
	//     {
	//       path: 'login',
	//       component: NbLoginComponent,
	//     },
	//     {
	//       path: 'register',
	//       component: NbRegisterComponent,
	//     },
	//     {
	//       path: 'logout',
	//       component: NbLogoutComponent,
	//     },
	//     {
	//       path: 'request-password',
	//       component: NbRequestPasswordComponent,
	//     },
	//     {
	//       path: 'reset-password',
	//       component: NbResetPasswordComponent,
	//     },
	//   ],
	// },

	{ path: "**", redirectTo: "internal" },
]

// const config: ExtraOptions = {
//   useHash: false,
// };

@NgModule({
	imports: [RouterModule.forRoot(routes,{preloadingStrategy: PreloadAllModules})],
	exports: [RouterModule],
})
export class AppRoutingModule {
}
