import { Component } from "@angular/core"

@Component({
	selector: "ngx-footer",
	styleUrls: ["./footer.component.scss"],
	template: `
    <span class="created-by">    
    </span>
    <div class="socials">
    </div>
  `,
})
export class FooterComponent {
}
