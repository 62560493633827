import { Component, OnInit } from "@angular/core"
import { ChangeDetectorRef } from "@angular/core"
import { Router } from "@angular/router"
import { apiUrl } from "../../global/global"
import { environment } from "./../../../environments/environment"
import { loginModel, resetPasswordModel } from "../../shared/models/login-models"
import { FormGroup, AbstractControl, FormBuilder, Validators, FormControl } from "@angular/forms"
import {HttpParams} from "@angular/common/http"
import { AdminService } from "../../service/admin.service"
import * as CryptoJS from "crypto-js"

@Component({
	selector: "ngx-reset-pwd",
	templateUrl: "./reset-pwd.component.html",
	styleUrls: ["./reset-pwd.component.scss"]
})
export class ResetPwdComponent implements OnInit {
	isTextFieldType1=false
	isTextFieldType2=false
	showError =false
	showError1 =false
	form: FormGroup
	token
	constructor(cd: ChangeDetectorRef, 
    private router: Router,
    private formBuilder: FormBuilder,
    private api: AdminService,) { 
		this.form = this.formBuilder.group({
     
			newPwd : ["",  Validators.compose([Validators.required, Validators.minLength(8)])],
			confirm: ["",  Validators.compose([Validators.required, Validators.minLength(8)])]
    
		})
	}

	ngOnInit(): void {
    
		this.token = window.location.href.split("/")[window.location.href.split("/").length - 1]
		localStorage.setItem("adminToken",this.token)
	}
	// change password
	ChangePwd(){
    
		let params = new HttpParams()
		if(this.form.valid && this.form.value.confirm == this.form.value.newPwd) {
			// this.loading = true;
			const data = {
				// 'id':this.route.snapshot.paramMap.get('id'),
				"password": this.form.value.newPwd,
			}
			params = params.append("token",this.token)
			params = params.append("newPassword", this.form.value.newPwd)
			// params['password']= this.form.value.newPwd;
			this.api.putData(apiUrl._putresetPassword,params)
				.subscribe(() => {
    
					// this.loading = false;

					localStorage.removeItem("adminToken")

					this.router.navigate(["/login"])

					this.api.showAlert("Password Change Successfully", "")
  
				})
		}else if(this.form.value.confirm != this.form.value.newPwd){
			this.showError1 = true
			setTimeout(()=>{
				this.showError1 = false
			},5000)
		}else{
			this.showError = true
			setTimeout(()=>{
				this.showError = false
			},5000)
		}
	}
	// show hide pwd
	togglePasswordFieldType(num){
		if(num==1){
			this.isTextFieldType1 = !this.isTextFieldType1
		}else{
    
			this.isTextFieldType2 = !this.isTextFieldType2
		}
	}

}
