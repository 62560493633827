import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core"
import { NbMediaBreakpointsService, NbMenuService, NbSidebarService, NbThemeService } from "@nebular/theme"
import swal from "sweetalert2"
import { LayoutService } from "../../../@core/utils"
import { map, takeUntil } from "rxjs/operators"
import { Router } from "@angular/router"
import { Subject } from "rxjs"
import { NbDialogService } from "@nebular/theme"
import { DataSharingService } from "../../../service/data.share.service"
import { environment } from "../../../../environments/environment"
import { HttpParams, HttpClient } from "@angular/common/http"
import { apiUrl } from "../../../global/global"
import { AdminService } from "../../../service/admin.service"
@Component({
	selector: "ngx-header",
	styleUrls: ["./header.component.scss"],
	templateUrl: "./header.component.html",
})
export class HeaderComponent implements OnInit, OnDestroy {
	firstName
	profilePic
	allData
	BaseUrl = environment.BaseUrl
	private destroy$: Subject<void> = new Subject<void>()
	userPictureOnly = false
	user: any
	toastRef

	themes = [
		{
			value: "default",
			name: "Light",
		},
		{
			value: "dark",
			name: "Dark",
		},
		{
			value: "cosmic",
			name: "Cosmic",
		},
		{
			value: "corporate",
			name: "Corporate",
		},
	]
	notificationMenu = [{ title: "View more", link: "/notification-management" }]
	currentTheme = "default"

	userMenu = [{ title: "Profile", link: "/profile-management" }, { title: "Log out", }]
	@ViewChild("dialog") private logoutdialog
	constructor(private sidebarService: NbSidebarService,
		private api: AdminService,
		private menuService: NbMenuService,
		private themeService: NbThemeService,
		private layoutService: LayoutService,
		private breakpointService: NbMediaBreakpointsService,
		private router: Router,
		private dialogService: NbDialogService,
		private dataSharingService: DataSharingService) {

		this.dataSharingService.headerProfile.subscribe(value => {
			const data = localStorage.getItem("adminDetail")
			this.allData = JSON.parse(data)
			this.firstName = JSON.parse(data).firstName + " " + JSON.parse(data).lastName
			this.profilePic = this.BaseUrl + JSON.parse(data).image
		})


	}

	ngOnInit() {
		this.currentTheme = this.themeService.currentTheme
		// .subscribe((users: any) => this.user = users.nick);
		const data = localStorage.getItem("adminDetail")
		this.allData = JSON.parse(data)
		this.firstName = JSON.parse(data).firstName + " " + JSON.parse(data).lastName
		this.profilePic = this.BaseUrl + JSON.parse(data).image


		const { xl } = this.breakpointService.getBreakpointsMap()
		this.themeService.onMediaQueryChange()
			.pipe(
				map(([, currentBreakpoint]) => currentBreakpoint.width < xl),
				takeUntil(this.destroy$),
			)
			.subscribe((isLessThanXl: boolean) => this.userPictureOnly = isLessThanXl)

		this.themeService.onThemeChange()
			.pipe(
				map(({ name }) => name),
				takeUntil(this.destroy$),
			)
			.subscribe(themeName => this.currentTheme = themeName)

		this.menuService.onItemClick()
			.subscribe((event) => {
				console.log(event)
				this.onContecxtItemSelection(event.item.title, "")
			})
		this.getnotification()
	}

	ngOnDestroy() {
		this.destroy$.next()
		this.destroy$.complete()
	}
	getnotification() {
		let params = new HttpParams()

		params = params.append("limit", 5)
		this.api.getData(apiUrl._notification, params)
			.subscribe(async (res) => {
				console.log(res.data.rows)
				for (let index = 0; index < res.data.rows.length; index++) {
					this.notificationMenu.unshift({ title: res.data.rows[index].title, link: ("/notification-management/detail/" + res.data.rows[index].id) })

				}
				// console.log(this.notificationMenu)
			})

	}
	changeTheme(event) {

		this.dataSharingService.themeChange.next(event)
		this.themeService.changeTheme(event)
	}

	toggleSidebar(): boolean {
		this.sidebarService.toggle(true, "menu-sidebar")
		this.layoutService.changeLayoutSize()

		return false
	}

	navigateHome() {
		this.menuService.navigateHome()
		return false
	}
	ProfileClicked() {
		alert()
	}

	onContecxtItemSelection(title, dialog) {
		console.log(title)
		if (title == "Log out") {
			this.logout()
			// this.dialogService.open(dialog, { context: 'this is some additional data passed to dialog' });

		}
	}

	logout() {

		swal.fire({
			title: "<h2>Confirmation</h2>",
			html: "<p>Are you sure you want to logout ?</p>",

			showCloseButton: false,
			showCancelButton: true,
			focusConfirm: false,
			confirmButtonAriaLabel: "Ok",
			cancelButtonAriaLabel: "Cancel",
			confirmButtonColor: "#7066e0",
			cancelButtonColor: "",
			cancelButtonText: "Cancel",
			confirmButtonText: "Ok",

			customClass: {
				container: "confirm-alert"
			},

		}).then((result) => {
			if (result.value) {
				localStorage.removeItem("accessToken")
				this.router.navigate(["/login"])
			}
		})



	}

}
