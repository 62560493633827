import { Injectable } from "@angular/core"
import { HttpClient, HttpParams, HttpHeaders } from "@angular/common/http"
import { environment} from "../../environments/environment"
import swal from "sweetalert2"
import { Router } from "@angular/router"
import { NbGlobalPhysicalPosition, NbToastrService ,NbComponentStatus} from "@nebular/theme"
import { generalModel, icons, menu, BtnText, permission, tabArray, optionArray } from "../shared/models/general-modals"
import { Subject } from "rxjs"
import { apiUrl } from "../global/global"

@Injectable({
	providedIn: "root"
}) 
export class AdminService {
	apiEndpoint = ""
	showLoader = true
	icon = icons
	access_token: any
	menu = menu
	btnText = BtnText
	generalModel = new generalModel()
	Permission = permission
	public _albums = []
	constructor(private http: HttpClient, private router: Router,public toastrService: NbToastrService) {
		this.apiEndpoint = environment.apiUrl
		this.access_token = localStorage.getItem("access_token")
		//console.log(this.access_token, " this.access_token")
		// if(!this.access_token){
		//   this.router.navigate(['/login']);
		// }

	}

	httpOptions = {
		headers: new HttpHeaders({
			"Content-Type": "application/json",
		})
	}

	getData(url, param) {
		return this.http.get<any>(this.apiEndpoint + url, { params: param })
	}

	getDetail(url, param) {
		return this.http.get<any>(this.apiEndpoint + url, { params: param })
	}

	getDetail1(url, param) {
		return this.http.get<any>(this.apiEndpoint + url, { params: param })
	}


	putData(url, param) {
		return this.http.put<any>(this.apiEndpoint + url,  param )
	}

	postData(url, value) {
		return this.http.post<any>(this.apiEndpoint + url, value)
	}


	deleteData(url, param) {
		const options = {
			headers: new HttpHeaders({
				"Content-Type": "application/json",
			}),
			body:param,
		}
		return this.http.delete<any>(this.apiEndpoint + url, options)
	}


	/*codes */
	// getDataList(url, value) {
	//   return this.http.get<any>(this.apiEndpoint + url, value);
	// }

  

	showAlert(title: string, text: string) {
		this.toastrService.show(title || "Success", `Toast ${text}`)
  
	}

	errorAlert(text, status1) {
		swal.fire({
			title: "Oops...",
			text: text,    
		}).then((result) => {
			if (status1) {
				localStorage.removeItem("access_token")
				localStorage.removeItem("accessToken")
				localStorage.removeItem("type-redirect")
				localStorage.removeItem("image")
				localStorage.removeItem("permissionData")
				this.router.navigate(["/login"])
			}
		})
	}
 


	toast(title, text) {
		const toast = swal.mixin({
			toast: true,
			position: "top-end",
			showConfirmButton: false,
			timer: 4000,
			background: "#fff",
			icon: "success",
			customClass: {
				container: "success-alert"}
		})
		toast.fire({
			title: " " + title,
			text: text
		})
	}

	toastAlert(title, text) {
		const toast = swal.mixin({
			toast: true,
			position: "top-end",
			showConfirmButton: false,
			timer: 6000,
			background: "#AE0019",
			icon: "error",
			customClass: {
				container: "error-alert"}
		})
		toast.fire({
			title: " " + title,
			text: text
		})
	}


	// Show and hide Loader
	private loaderSubject = new Subject<boolean>()
	loaderState = this.loaderSubject.asObservable()

	loader(value) { this.loaderSubject.next(value) }

	setdata(data) {
		for (const x of tabArray) {
			const array1 = {}
			for (const y of x.value) {
				array1[optionArray[y].name] = (data[x.name] == undefined || data[x.name][optionArray[y].name] == undefined ? false : data[x.name][optionArray[y].name])
			}
			permission[x.name] = array1
		}
		permission["status"] = "updated"
		return true
	}

}
