import { Component, OnInit } from "@angular/core"
import { AnalyticsService } from "./@core/utils/analytics.service"
import { SeoService } from "./@core/utils/seo.service"

import { Location } from "@angular/common"
@Component({
	selector: "ngx-app",
	template: `
   <nb-layout *ngIf="isLogin">
       <nb-layout-column style="padding: 0px !important;">
         <router-outlet></router-outlet>
       </nb-layout-column>
 </nb-layout>
 <router-outlet *ngIf="!isLogin"></router-outlet>
 `,
})
export class AppComponent implements OnInit {
	isLogin = false
	constructor(private location: Location, private analytics: AnalyticsService, private seoService: SeoService) {

		this.location.onUrlChange(x => this.isLogin = (x == "/login" ? true : false))
		console.log(this.isLogin)
	}

	ngOnInit(): void {
		this.analytics.trackPageViews()
		this.seoService.trackCanonicalChanges()
	}
}
