import { Component, OnInit,OnDestroy } from "@angular/core"
import { ChangeDetectorRef } from "@angular/core"
import { Router } from "@angular/router"
import { apiUrl } from "../../global/global"
import { environment } from "./../../../environments/environment"
import { loginModel, resetPasswordModel } from "../../shared/models/login-models"
import { FormGroup, AbstractControl, FormBuilder, Validators, FormControl } from "@angular/forms"

import { map, takeUntil } from "rxjs/operators"
import { NbMediaBreakpointsService, NbMenuService, NbSidebarService, NbThemeService } from "@nebular/theme"
import { AdminService } from "../../service/admin.service"
import * as CryptoJS from "crypto-js"
import { Subject } from "rxjs"
import { DataSharingService } from "../../service/data.share.service"
@Component({
	selector: "ngx-login",
	templateUrl: "./login.component.html",
	styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit,OnDestroy {

	currentTheme = "default"
	protected cd: ChangeDetectorRef
	public form: FormGroup
	public resetPasswordForm: FormGroup
	showError = false
	show = true
	private destroy$: Subject<void> = new Subject<void>()
	public loading = false
	loginModel = new loginModel()
	resetPasswordModel = new resetPasswordModel()
	showFilp = false
	rememberme = false
	userPictureOnly = false
	tokenFromUI = "0123456789123456"
	isRemember = false
	themes = [
		{
			value: "default",
			name: "Light",
		},
		{
			value: "dark",
			name: "Dark",
		},
		{
			value: "cosmic",
			name: "Cosmic",
		},
		{
			value: "corporate",
			name: "Corporate",
		},  
	]
	constructor( cd: ChangeDetectorRef, 
               private router: Router,
               private formBuilder: FormBuilder,
               private api: AdminService,
               private breakpointService: NbMediaBreakpointsService,
               private sidebarService: NbSidebarService,
              private menuService: NbMenuService,
              private themeService: NbThemeService,
              private dataSharingService: DataSharingService
              
	) { 
		this.form = this.formBuilder.group({
			email: ["", Validators.compose([Validators.required, Validators.email ])],
			password: ["", Validators.compose([Validators.required] ) ]
		})
		this.resetPasswordForm = this.formBuilder.group({
			email: ["", Validators.compose([Validators.required, Validators.email ])],

		})
	}
	ngOnInit(): void {
    
		this.currentTheme = this.themeService.currentTheme
		const data = localStorage.getItem("accessToken")
		if (data)
			this.router.navigate(["/dashboard"],{ replaceUrl: true })
		const rememberme = localStorage.getItem("rememberme")
		console.log(rememberme, "rememberme")
		if (rememberme == "1") {
			this.loginModel.email = localStorage.getItem("email")
			this.decryptUsingAES256(localStorage.getItem("password"))
			this.isRemember = true
			this.rememberme = true
		}
    
		const { xl } = this.breakpointService.getBreakpointsMap()
		this.themeService.onMediaQueryChange()
			.pipe(
				map(([, currentBreakpoint]) => currentBreakpoint.width < xl),
				takeUntil(this.destroy$),
			)
			.subscribe((isLessThanXl: boolean) => this.userPictureOnly = isLessThanXl)

		this.themeService.onThemeChange()
			.pipe(
				map(({ name }) => name),
				takeUntil(this.destroy$),
			)
			.subscribe(themeName => this.currentTheme = themeName)
	}
	ngOnDestroy() {
		this.destroy$.next()
		this.destroy$.complete()
	}

	changeTheme(event) {
   
		this.dataSharingService.themeChange.next(event)
		this.themeService.changeTheme(event)
		this.dataSharingService.themeChange.subscribe((item) => {
			console.log(item)
		})
		const body = document.getElementsByTagName("body")[0]
		console.log(body)
		body.classList.add("body-landing")
	}
	flipAnimation(value) {
		this.showFilp = value
	}

	/**  **/
	loginFun() {
 
		console.log(this.form)
		if (this.form.valid) {
			this.api.apiEndpoint = environment.apiUrl
			this.loading = true
       
			const data = {
				"email": this.loginModel.email,
				"password": this.loginModel.password,
   
			}
			this.api.postData(apiUrl._adminLogin, data)
				.subscribe(async (res) => {
					console.log(res, "login=")
					this.loading = false
           
					localStorage.setItem("accessToken", res.data.accessToken)
					localStorage.setItem("adminDetail", JSON.stringify(res.data.adminDetails))
      

					this.router.navigate(["/dashboard"])
					this.api.toast("Admin", "Login successful!")


					if (this.rememberme) {
						const encrptedPassword = await this.encryptUsingAES256(this.loginModel.password)
						localStorage.setItem("rememberme", "1")
						localStorage.setItem("email", this.loginModel.email)
						localStorage.setItem("password", encrptedPassword.toString())
					} else {
						localStorage.setItem("rememberme", "0")
						localStorage.setItem("email", "")
						localStorage.setItem("password", "")
					}
				},
				err => {
					console.log(err.error.message, "errr")
					this.api.toastAlert("Error", err.error.message)
					this.loading = false
				})
		} else {
			this.showError = true 
			setTimeout(() => {
				this.showError = false
			}, 5000)
		}
	}
	/** **/
	encryptUsingAES256(value) {
		return new Promise((resolve, reject) => {
			const ciphertext = CryptoJS.AES.encrypt(value, this.tokenFromUI)
			resolve(ciphertext)
		})

	}
	decryptUsingAES256(value) {
		const bytes = CryptoJS.AES.decrypt(value.toString(), this.tokenFromUI)
		const plaintext = bytes.toString(CryptoJS.enc.Utf8)
		this.loginModel.password = plaintext
	}
	/** **/
	changeRememberme(event) {
		console.log("checked", event.target.checked)
		this.rememberme = event.target.checked
	}
	/** **/
	resetPassword() {
		console.log(this.resetPasswordForm.valid,this.resetPasswordForm.value)
		if (this.resetPasswordForm.valid) {
			this.api.apiEndpoint = environment.apiUrl
			this.loading = true
			const data = {
				"email": this.resetPasswordModel.email
			}
			this.api.postData(apiUrl._forgotPassword, data)
				.subscribe(() => {
					this.loading = false

				},
				err => {
					console.log(err)
					this.loading = false
				})
		} else {
			this.showError = true
			setTimeout(() => {
				this.showError = false
			}, 5000)
		}
	}
}
