import { Injectable } from "@angular/core"
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from "@angular/router"
import { permission } from "../shared/models/general-modals"
import { AdminService } from "./admin.service"

@Injectable({
	providedIn: "root"
})
export class AuthGuard implements CanActivate {
	constructor(private router: Router,private admin:AdminService){}
	canActivate()  {
		const token = localStorage.getItem("accessToken")   
		if (token) {    
			return true
		}
		this.router.navigate([""])   
		return false
	}
}
